/**
 =========================================================
 * Soft UI Dashboard React - v4.0.1
 =========================================================

 * Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
 * Copyright 2023 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import AccountNavBar from "examples/Navbars/AccountNavBar";
import Footer from "examples/Footer";
import Table from "examples/Tables/Table";

// Data
import authorsTableData, {transactionTableData} from "layouts/tables/data/authorsTableData";
import projectsTableData from "layouts/tables/data/projectsTableData";
import Grid from "@mui/material/Grid";
import SoftButton from "../../components/SoftButton";
import {FilterTable} from "../../components/Filter";
import {useEffect, useState} from "react";
import {from} from "stylis";
import _, {intersection} from "lodash";
import TransactionsDataGrid, {DateGridTxs2} from "../../components/TransactionsDataGrid";
import {useNavigate, useParams} from "react-router-dom";
import {ACCOUNT_TO_DATA, MOCK_DATA_2} from "../../data/accountingData";
import {v4 as uuidv4} from 'uuid';
import {
    calculateAccountUSDValue,
    calculateTransactionInflowAndOutflowUSDValue,
    getAllAccountUSDValue,
    getAllTransactionToAddresses,
    getAssetDistributionAssetBased,
    getAssetDistributionCategoryBased,
    getAssetDistributionChartData,
    getAssetsOutInTx,
    getAssetsReceivedInTx,
    getTokens,
    getTokensInTx,
    getYearReports,
    isValidAddress,
    mergeTokenData,
    prettyPrint,
    prettyPrintAddress
} from "../../common/utils";
import {TokenTracker} from "../../components/TokenTracker";
import * as React from "react";
import {Button, Loading} from "@nextui-org/react";
import {FIFOCalculator} from "../../components/FIFOCalculator";
import {atom, RecoilRoot, selector, useRecoilState} from "recoil";
import {accountsState} from "../../data/accountData";
import MiniStatisticsCard from "../../examples/Cards/StatisticsCards/MiniStatisticsCard";
import {unstable_useEnhancedEffect} from "@mui/material";
import {TokenHoldingPieChart} from "../../components/TokenHoldingPieChart";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import "./styles.css"
import {RenderProfitLossCell, RenderSimpleNumberField, RenderSimpleTextField} from "../../components/common";
import {Toolbar} from "primereact/toolbar";
import {Calendar} from "primereact/calendar";
import PieChart from "../../components2/charts/PieChart";
import Widget from "../../components2/widget/Widget";
import {MdBarChart, MdSyncAlt} from "react-icons/md";
import PieChartCard from "../../components2/card/PieChartCard";
import WeeklyRevenue from "../../components2/WeeklyRevenue";
import {DEFILO_DEV, DEFILO_PROD} from "../../consts";

function getAllTokens(data) {
    let tokensOptions = []
    let tokensReviewd = {}
    for (const tx of data) {
        let tokensInTx = tx.tokensIn.concat(tx.tokensOut)
        for (let token of tokensInTx) {
            let tokenInfo = token.tokenInfo
            if (tokensReviewd[tokenInfo.addr.toLowerCase()] == undefined) {
                tokensReviewd[tokenInfo.addr.toLowerCase()] = true
                tokensOptions.push({
                    "name": tokenInfo.symbol,
                    "value": tokenInfo.symbol
                })

            }
        }
    }

    return tokensOptions

}

function getAllOperations(initialData) {
    let operationTypes = _.uniqBy(initialData, "operation").map(x => {
        return {"name": x.operation}
    })

    return operationTypes
}


function Tables() {

    let ACTIVE_TAB_URL_PARM_MAPPER = {
        "explorer": 0,
        "portfolio": 1,
        "lp": 2,
        "staking": 3,
        "nfts": 4,
        "reports": 5,
        "accountstatement": 6

    }

    let params = useParams();
    const {columns: transactionTableColumns} = transactionTableData([])
    const [data, setData] = useState([])
    const [initialData, setInitialData] = useState([])
    const [loading, setLoading] = useState(false)
    const [curRowData, setCurRowData] = useState(data)
    const [portfolioData, setPortfolioData] = useState([])
    const [isFilterOpen, setIsFilterOpen] = useState(false)
    const [dateFilter, setDateFilter] = useState()

    const [typeFilter, setTypesFilter] = useState([])
    const [transactionHashFilter, setTransactionHashFilter] = useState(null)
    const [fromFilter, setFromFilter] = useState(null)
    const [toFilter, setToFilter] = useState(null)
    const [tokensFilter, setTokensFilter] = useState([])
    const [assetDirectionType, setAssetDirectionType] = useState([])
    const [amountMinFilter, setAmountMinFilter] = useState(0)
    // const [tokenDialogOpen, setTokenDialogOpen ] = useState(false)
    const [tokenClicked, setTokenClicked] = useState("")
    const [pnlExplainedTx, setPnlExplainedTx] = useState("")
    const [chains, setChains] = useState(["bsc", "eth"])

    const [accounts, setAccounts] = useRecoilState(accountsState);
    const [currentAccount, setCurrentAccount] = useState(params.addr)
    const [nftData, setNFTData] = useState([])
    const [uniV2LP, setUniV2LP] = useState([])
    const [stakingPositionData, setStakingPositionData] = useState([])
    const [endOfYearFinancialStatements, setEndOfYearFinancialStatements] = useState({})
    const navigate = useNavigate();

    // validate the params if they are not good send to error.
    useEffect(() => {
        // first check that the address is valid.
        if (!isValidAddress(params.addr)) {
            navigate("/error")
        }


    }, [params])


    useEffect(() => {
    }, [accounts])

    useEffect(() => {
    }, [currentAccount])

    useEffect(() => {
        let operationTypes = _.uniqBy(initialData, "operation").map(x => {
            return {"name": x.operation}
        })
        setTypesFilter(operationTypes.map(x => x.name))

    }, [initialData])


    const initFilters = () => {
        setChains(["bsc", "eth"])
        setTokensFilter(getAllTokens(initialData).map(x => x.value))
        setFromFilter(null)
        setTransactionHashFilter(null)
        setTypesFilter(getAllOperations(initialData).map(x => x.name))
    }


    const getTokensInRow = (row) => {
        let tokensInNames = row.tokensIn.map(x => x.tokenInfo.symbol)
        let tokensOutNames = row.tokensOut.map(x => x.tokenInfo.symbol)
        let allTokens = tokensInNames.concat(tokensOutNames)
        return allTokens
    }


    const fetchData = async () => {
        try {
            let addressRequested = params.addr.toLowerCase()
            let backend_url = DEFILO_DEV
            if (process.env.REACT_APP_ENV == "PROD") {
                backend_url = DEFILO_PROD
            }


            const response = await fetch(`${backend_url}/demo/?account=${params.addr.toLowerCase()}`)

            const allData = await response.json()

            const jsonData = JSON.parse(allData.txList)
            const portfolioData = JSON.parse(allData.tokens)
            const nftData = allData?.nfts ? JSON.parse(allData?.nfts) : []
            const uniV2LP = allData?.uniV2LPTokens ? JSON.parse(allData?.uniV2LPTokens) : []
            const stakingPositionData = allData?.stakingPositions ? JSON.parse(allData?.stakingPositions) : []
            const endOfYearFS = allData?.endOfYearFinancialStatement ? JSON.parse(allData?.endOfYearFinancialStatement) : {}

            let realtxs = jsonData.filter(tx => tx.shouldIgnore == false)
            setTokensFilter(getAllTokens(realtxs).map(x => x?.name))
            setData(realtxs);
            setInitialData(realtxs)
            setPortfolioData(portfolioData)
            setNFTData(nftData)
            setUniV2LP(uniV2LP)
            setEndOfYearFinancialStatements(endOfYearFS)
            setStakingPositionData(stakingPositionData)
            setLoading(false)

            let newAccountData = {
                [params.addr.toLowerCase()]: {
                    "txList": realtxs,
                    "portfolio": portfolioData,
                    "nfts": nftData,
                    "stakingPositions": stakingPositionData,
                    "uniV2LPTokens": uniV2LP,
                    "endOfYearFinancialStatement": endOfYearFS,
                    "label": prettyPrintAddress(params.addr),
                    "address": params.addr,
                    "isLoading": false,
                    "lastSyncedTime": new Date().getTime(),
                    "lastSyncedPortfolioTime": new Date().getTime()

                }
            }
            setAccounts(prevData => Object.assign({}, prevData, newAccountData))

        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };


    useEffect(() => {
        initFilters()
        setCurrentAccount(params.addr)
        if (params.addr == "overview") {
            let txData = []
            let nftData = []
            let uniV2LP = []
            let combinedStakingPositionData = []

            for (const account of Object.keys(accounts)) {
                txData = txData.concat(accounts[account].txList)
                nftData = nftData.concat(accounts[account].nfts || [])
                uniV2LP = uniV2LP.concat(accounts[account].uniV2LPTokens || [])
                combinedStakingPositionData = combinedStakingPositionData.concat(accounts[account].stakingPositions || [])
            }

            let tokensData = mergeTokenData(accounts)
            setLoading(false)
            setTokensFilter(getAllTokens(txData).map(x => x?.name))
            setNFTData([...nftData])
            setData([...txData]);
            setInitialData([...txData])
            setUniV2LP([...uniV2LP])
            setStakingPositionData([...combinedStakingPositionData])
            setEndOfYearFinancialStatements({...endOfYearFinancialStatements})
            setPortfolioData({"tokens": tokensData})

        } else if (accounts[params.addr.toLowerCase()]) {
            // the account is already synced.
            if (accounts[params.addr.toLowerCase()]?.lastSyncedTime != 0) {

                let data = [...accounts[params.addr.toLowerCase()].txList]
                setTokensFilter(getAllTokens(data).map(x => x?.name))
                setData([...data]);
                setInitialData([...data])
                setPortfolioData({...accounts[params.addr.toLowerCase()].portfolio})
                setNFTData([...accounts[params.addr.toLowerCase()].nfts])
                setUniV2LP([...accounts[params.addr.toLowerCase()].uniV2LPTokens])
                setEndOfYearFinancialStatements({...accounts[params.addr.toLowerCase()].endOfYearFinancialStatement})
                setStakingPositionData([...accounts[params.addr.toLowerCase()].stakingPositions])
                // fix the bug if moving between account that is loading to account that is not loading the status of the loader should change.
                // however if moving between account that is not loaded to account that is loading - make sure you save it that it's loading to avoid multiple request to the backend
                if (accounts[params.addr.toLowerCase()].isLoading == true) {
                    setLoading(true)
                } else {
                    setLoading(false)
                }
            }
            // the account is not loading
            else if (accounts[params.addr.toLowerCase()].isLoading == false) {
                setData([])
                setInitialData([])
                setTokensFilter([])
                setPortfolioData([])
                setNFTData([])
                setUniV2LP([])
                setStakingPositionData([])
                setEndOfYearFinancialStatements({})
                setLoading(true)
                // add to the accounts, indicate that the account is loading in case moving to it.
                let newAccountData = {
                    [params.addr.toLowerCase()]: {
                        "txList": [], "portfolio": {"tokens": []},
                        "nfts": [],
                        "uniV2LPTokens": [],
                        "stakingPositions": [],
                        "label": prettyPrintAddress(params.addr),
                        "address": params.addr,
                        "isLoading": true,
                    }
                }
                setAccounts(prevData => Object.assign({}, prevData, newAccountData))
                fetchData()
            }

        }
        // get the data from server..
        else if (isValidAddress(params.addr)) {
            setData([])
            setInitialData([])
            setTokensFilter([])
            setPortfolioData([])
            setNFTData([])
            setUniV2LP([])
            setStakingPositionData([])
            setLoading(true)
            // add to the accounts, indicate that the account is loading in case moving to it.
            let newAccountData = {
                [params.addr.toLowerCase()]: {
                    "txList": [], "portfolio": {"tokens": []},
                    "nfts": [],
                    "uniV2LPTokens": [],
                    "stakingPositions": [],
                    "label": prettyPrintAddress(params.addr),
                    "address": params.addr,
                    "isLoading": true,
                }
            }
            setAccounts(prevData => Object.assign({}, prevData, newAccountData))
            fetchData()
        }


    }, [params.addr])


    useEffect(() => {
        if (accounts[params.addr.toLowerCase()]) {
            let data = [...accounts[params.addr.toLowerCase()].txList]
            setTokensFilter(getAllTokens(data).map(x => x?.name))
            setData([...data]);
            setInitialData([...data])
        } else {
            fetchData();
        }
    }, []);


    const [filters, setFilters] = useState({
        "dates": {
            "value": dateFilter,
            "set": setDateFilter
        },
        "type": {
            "value": typeFilter,
            "set": setTypesFilter
        },
        "hash": {
            "value": transactionHashFilter,
            "set": setTransactionHashFilter
        },
        "from": {
            "value": fromFilter,
            "set": setFromFilter
        },
        "to": {
            "value": toFilter,
            "set": setToFilter
        },
        "tokens": {"value": tokensFilter, "set": setTokensFilter},
        "assetDirection": {"value": assetDirectionType, "set": setAssetDirectionType},
        "chains": {
            'value': chains, "set": setChains,
        },
        "amountMin": {
            "value": amountMinFilter,
            "set": setAmountMinFilter
        }
    })


    const [PNLCalculatorDialogVisible, setPNLCalculatorDialogVisible] = useState(false)
    const [tokenTrackerDialogVisible, setTokenTrackerDialogVisible] = useState(false)

    useEffect(() => {
        setCurRowData(data)
    }, [data])


    useEffect(() => {
    }, [filters])

    useEffect(() => {
        let afterFilters = initialData
        let newFilters = filters
        let txs = transactionHashFilter?.map(x => x.toLowerCase()) || []

        if (txs != null) {
            if (txs.length != 0) {
                afterFilters = afterFilters?.filter(x => txs.includes(x.hash?.toLowerCase()))
            }
            newFilters.hash.value = txs
        }

        if (fromFilter != null) {
            if (fromFilter.length != 0) {
                afterFilters = afterFilters.filter(x => fromFilter.map(x=>x.toLowerCase()).includes(x.from?.toLowerCase()))
            }
            newFilters.from.value = fromFilter
        }
        if (toFilter != null) {
            let toFilterLowerCase = toFilter.map(x=>x.toLowerCase())
            if (toFilter.length != 0) {
                afterFilters = afterFilters.filter(x => _.intersection(toFilterLowerCase, getAllTransactionToAddresses(x).map(x=>x.toLowerCase())).length >= 1)
            }
            newFilters.to.value = toFilter
        }

        if (chains) {
            afterFilters = afterFilters.filter(x => chains.includes(x.chain))
            newFilters.chains.value = chains
        }

        if (tokensFilter != null) {

            let temp = []
            for (const row of afterFilters) {
                let tokensInRow = getTokensInRow(row)
                if (intersection(tokensInRow, tokensFilter).length >= 1) {
                    temp.push(row)
                }
            }
            afterFilters = temp
            newFilters.tokens.value = tokensFilter
        }

        // if (assetDirectionType.length > 0) {
        //     let temp = []
        //
        //     for (const row of afterFilters) {
        //         if (assetDirectionType.includes("Send Asset")) {
        //             let assetsOut = getAssetsOutInTx(row)
        //             if (intersection(assetsOut, tokensFilter).length >= 1) {
        //                 temp.push(row)
        //             }
        //
        //         }
        //         if (assetDirectionType.includes("Receive Asset")) {
        //             let tokensInRow = getAssetsReceivedInTx(row)
        //             if (intersection(tokensInRow, tokensFilter).length >= 1) {
        //                 temp.push(row)
        //             }
        //         }
        //     }
        //     afterFilters = temp
        //     console.log("Asset direction", assetDirectionType)
        //     newFilters.assetDirection.value = assetDirectionType
        // }

        if (typeFilter != null) {
            afterFilters = afterFilters.filter(tx => typeFilter.includes(tx.operation))
            newFilters.type.value = typeFilter
        }
        if (amountMinFilter != 0) {
            // get transaction total inflow and outflow.
            afterFilters = afterFilters.filter(tx => calculateTransactionInflowAndOutflowUSDValue(tx) >= amountMinFilter)

            newFilters.amountMin.value = amountMinFilter
        }

        setFilters(newFilters)
        setCurRowData(afterFilters)

    }, [transactionHashFilter, fromFilter, toFilter, tokensFilter, typeFilter, assetDirectionType, chains, amountMinFilter])


    const customHeader = (date, setDate, totalProfit) => {
        let textColor = totalProfit < 0 ? {color: "red"} : {color: "green"}
        let currentYear = new Date().getFullYear()
        return (
            <div>
                <div className="flex flex-wrap align-items-center justify-content-between gap-2">
                    <span className="text-xl text-900 font-bold">{date.getFullYear()} Profit & Loss</span>
                    <Calendar view="year" onChange={(e) => {
                        setDate(e.value)
                    }} dateFormat="yy" minDate={new Date("1/1/2021")} maxDate={new Date("1/1/2023")}/>
                </div>
                <div>
                    <span>Total Profit / Loss  :<span style={textColor}>{prettyPrint(totalProfit) + "$"} </span></span>

                </div>
                <div>
                    {date.getFullYear() == currentYear && <span style={{fontSize: "10px"}}>
                      * Only an estimation according to realtime portfolio value.
                    </span>}
                </div>
            </div>

        )

    }

    return (

        <DashboardLayout>
            <AccountNavBar/>
            <SoftBox py={3}>
                <SoftBox mb={3}>
                    <SoftBox mb={3}>
                        <Grid container spacing={3} alignItems="stretch">
                            <Grid item xs={12} sm={4}>

                                <MiniStatisticsCard
                                    title={{text: "Total Transactions Imported"}}
                                    count={initialData.length}
                                    // percentage={{ color: "success", text: "+55%" }}
                                    icon={{color: "info", component: "download"}}
                                />

                            </Grid>

                            <Grid item xs={12} sm={4}>
                                <MiniStatisticsCard
                                    title={{text: "Current Networth"}}
                                    count={prettyPrint(getAllAccountUSDValue((portfolioData?.tokens || []), uniV2LP || [], nftData || [])) + "$"}
                                    // percentage={{ color: "success", text: "+55%" }}
                                    icon={{color: "info", component: "paid"}}
                                />
                                {/*<Widget icon={<MdBarChart className="h-7 w-7"/>}*/}
                                {/*        title={"Current USD Balance"}*/}
                                {/*        subtitle={ + "$"}>*/}
                                {/*</Widget>*/}
                                {/*<MiniStatisticsCard*/}
                                {/*    title={{ text: "Current USD Balance"}}*/}
                                {/*    count={prettyPrint(calculateAccountUSDValue((portfolioData.tokens) || []) || 0) + "$"}*/}
                                {/*    // percentage={{ color: "success", text: `${prettyPrint(100000)}$`}}*/}
                                {/*    icon={{ color: "info", component: "paid" }}*/}
                                {/*/>*/}

                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <MiniStatisticsCard
                                    title={{text: "Current Year P&L"}}
                                    count={prettyPrint(_.sumBy(getYearReports(initialData || [], 1672574400, 1672574400 + 31540000, portfolioData || []), "pnl")) + "$"}
                                    // percentage={{ color: "success", text: "+55%" }}
                                    icon={{color: "info", component: "show_chart"}}
                                />


                            </Grid>
                            <Grid item container spacing={1} alignItems="stretch" flexDirection={"row"}>
                                {/*<Grid item xs={12} sm={4}>*/}

                                {/*    {(() => {*/}
                                {/*        const {labels, value} = getAssetDistributionCategoryBased(portfolioData?.tokens || [], uniV2LP || [], nftData || []);*/}
                                {/*        let options ={*/}
                                {/*            tooltip: {*/}
                                {/*                y: {*/}
                                {/*                formatter: function(val) {*/}
                                {/*                    let t = prettyPrint(val) + " $"*/}
                                {/*                    return t*/}
                                {/*                    }*/}
                                {/*                }*/}
                                {/*        }}*/}

                                {/*        return <PieChartCard pieChartData={value} labels={labels} defaultOptionsOverride={options} title={"Assets Allocated"}/>*/}
                                {/*    })()}*/}

                                {/*</Grid>*/}
                                {/*<Grid item xs={12} sm={4}>*/}
                                {/*    <PieChartCard pieChartData={[1,2,34,5,6,7,8,8]} labels={["Eth", "BTC", "XX", "XXX","XXXY","xxxx","dsadsad","Dsadas"]}/>*/}
                                {/*</Grid>*/}
                                {/*    <Grid item xs={12} sm={4}>*/}
                                {/*        {(() => {*/}
                                {/*            const {labels, value} = getAssetDistributionAssetBased(portfolioData?.tokens || [], uniV2LP || [], nftData || []);*/}
                                {/*            let options ={*/}
                                {/*                tooltip: {*/}
                                {/*                    y: {*/}
                                {/*                        formatter: function(val) {*/}
                                {/*                            let t = prettyPrint(val) + " $"*/}
                                {/*                            return t*/}
                                {/*                        }*/}
                                {/*                    }*/}
                                {/*                }}*/}

                                {/*            return <PieChartCard pieChartData={value} labels={labels} defaultOptionsOverride={options}  shouldShowLabelMenu={false} title={"Assets Distribution"}/>*/}
                                {/*        })()}*/}

                                {/*    </Grid>*/}

                            </Grid>
                            {/*{portfolioData.tokens &&*/}
                            {/*    <Grid item xs={12} sm={12}>*/}
                            {/*    {portfolioData.tokens && (*/}
                            {/*        <TokenHoldingPieChart data={getAssetDistributionChartData(getTokens(portfolioData.tokens))}/>)}*/}
                            {/*</Grid>}*/}

                        </Grid>
                    </SoftBox>

                    <Card>
                        {loading && (<Grid xs={12} style={{
                            height: "100vh",
                            width: "100%",
                            alignItems: "center",
                            display: "flex",
                            justifyContent: "center"
                        }}>
                            <Loading size="lg" type="points"/>
                        </Grid>)}


                        <SoftBox
                            sx={{
                                "& .MuiTableRow-root:not(:last-child)": {
                                    "& td": {
                                        borderBottom: ({borders: {borderWidth, borderColor}}) =>
                                            `${borderWidth[1]} solid ${borderColor}`,
                                    },
                                },
                            }}
                        >
                            {initialData != "" && (<DateGridTxs2 rows={[...curRowData]}
                                                                 activeTab_={ACTIVE_TAB_URL_PARM_MAPPER[params.activeTab.toLowerCase()] || 0}
                                                                 accountPortfolio={portfolioData}
                                                                 nftData={nftData}
                                                                 account={params.addr.toLowerCase()}
                                                                 setIsFilterOpen={setIsFilterOpen}
                                                                 onClickToken={(token) => {
                                                                     setTokenClicked(token)
                                                                     setTokenTrackerDialogVisible(true)
                                                                 }}
                                                                 onPNLExplainedClick={(value) => {
                                                                     setPnlExplainedTx(value)
                                                                     setPNLCalculatorDialogVisible(true)
                                                                 }
                                                                 }
                                                                 initialData={initialData}
                                                                 uniV2LP={uniV2LP}
                                                                 stakingPositions={stakingPositionData}
                                                                 endOfYearsFSC={endOfYearFinancialStatements}

                            />)}
                        </SoftBox>
                    </Card>
                </SoftBox>

            </SoftBox>
            <Footer/>
            <FilterTable openConfigurator={isFilterOpen} onClose={setIsFilterOpen} filters={filters}
                         data={authorsTableData} allTokens={getAllTokens(initialData)}
                         allOperations={getAllOperations(initialData)} typeFilter={typeFilter}></FilterTable>

            <TokenTracker txs={initialData}
                          tokenHoldingData={tokenClicked != "" ? portfolioData?.tokens?.filter(x => x.token_address?.toLowerCase() == tokenClicked.token_address?.toLowerCase())[0] : []}
                          tokenData={tokenClicked}
                          dialogVisible={tokenTrackerDialogVisible}
                          setDialogVisible={(visible) => {
                              setTokenTrackerDialogVisible(false)
                          }}></TokenTracker>

            <FIFOCalculator txs={initialData}
                            pnlExplainedData={pnlExplainedTx}
                            setDialogVisible={setPNLCalculatorDialogVisible}
                            dialogVisible={PNLCalculatorDialogVisible}
                            tokenHoldingData={
                                portfolioData?.tokens?.filter(x => x.token_address?.toLowerCase() == pnlExplainedTx?.token_address?.toLowerCase())[0] || []

                            }></FIFOCalculator>
        </DashboardLayout>
    );
}

export default Tables;
