import {Calendar} from "primereact/calendar";
import {
    getActiveAccountFromPath,
    getPNLCSV,
    getYearPaidOutGasFees,
    getYearReports,
    prettyPrint
} from "../../common/utils";
import * as React from "react";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {RenderProfitLossCell, RenderSimpleNumberField, RenderTokenCell} from "../common";
import {useEffect, useState} from "react";
import {Tooltip} from 'primereact/tooltip';
import "../../styles/styles.css"
import {Toolbar} from "primereact/toolbar";
import {Button} from "primereact/button";
import {useLocation} from "react-router-dom";

function customHeader2() {

}

const customHeader = (date, setDate, totalProfit, paidOutGasFeesUsd, paidOutGasFeesEther, yfr) => {
    let textColor = totalProfit < 0 ? {color: "red"} : {color: "green"}
    let currentYear = new Date().getFullYear()
    const { pathname } = useLocation();
    const currentAccount = getActiveAccountFromPath(pathname)


    return (
        <div>
            <div style={{display:"flex", justifyContent:"space-between"}} >
                <div>


                    <div className="flex flex-wrap align-items-center gap-2">
                        <span className="text-xl text-900 font-bold" style={{fontFamily:"Poppins"}}>Tax Reports</span>
                        <Calendar view="year" onChange={(e) => {
                            setDate(e.value)
                        }} dateFormat="yy" minDate={new Date("1/1/2021")} maxDate={new Date("1/1/2023")} value={date}/>
                    </div>
                    <div style={{"marginTop": "20px"}}>
                        <span>Total Profit / Loss: <span
                            style={textColor}>{prettyPrint(totalProfit) + "$"} </span></span>

                    </div>
                    <div>
                        <span>Gas Fees: <span
                            style={{color: "red"}}>-{prettyPrint(paidOutGasFeesUsd) + "$"} </span></span>
                    </div>
                    <div>
                        {date.getFullYear() == currentYear && <span style={{fontSize: "10px"}}>
                      * Only an estimation according to realtime portfolio value.
                    </span>}
                    </div>
                </div>
                <div>
                    <Button label="Export" icon="pi pi-file-excel" text raised size={"small"}
                                   style={{height: "40px", color: "#0072F5"}} onClick={() => {
                        getPNLCSV(currentAccount, date.getFullYear(), yfr)
                        }}/>

                </div>
            </div>


        </div>

    )

}



export const YearFinancialReports = ({initialData, accountPortfolio, onPNLExplainedClick}) => {
    const [date, setDate] = useState(new Date(1672531200000))


    const actionBodyTemplate = (params, startDate, endDate) => {
        return (<>
                <Tooltip target=".custom-target-icon" style={{fontSize: "10px"}}/>
                <i className="custom-target-icon pi pi-fw pi-question-circle"
                   data-pr-tooltip="Explain Calculation"
                   data-pr-position="bottom"
                   style={{cursor: "pointer"}}
                   onClick={() => {
                       onPNLExplainedClick({
                           "token_address": params.token_address,
                           "symbol": params.token_symbol,
                           "startDate": startDate,
                           "endDate": endDate
                       })
                   }}
                ></i>
            </>
        )
    };

    return (<>
        {/*<Toolbar style={{height: "fit-content", background: "none", border: "none"}}*/}
        {/*         right={ExportToCsv}></Toolbar>*/}

        <DataTable
            value={getYearReports(initialData, Number(date.getTime() / 1000), Number(date.getTime() / 1000) + 31540000, accountPortfolio)}
            rows={5}
            header={() => {
                let [paidOutGasFeesUsd, paidOutGasFeesEther] = getYearPaidOutGasFees(initialData, accountPortfolio.address, Number(date.getTime() / 1000), Number(date.getTime() / 1000) + 31540000)

                let yfr = getYearReports(initialData, Number(date.getTime() / 1000), Number(date.getTime() / 1000) + 31540000, accountPortfolio)
                let sum = 0
                for (const token of yfr) {
                    sum += token.pnl
                }
                return customHeader(date, setDate, sum, paidOutGasFeesUsd, paidOutGasFeesEther, yfr)
            }}
            paginator={true}
            sortField={"pnl"}
            defaultSortOrder={1}
            style={{height: "100%"}}
            sortOrder={-1}
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            className={"allTransactionsTable"}
        >
            <Column field={"token_symbol"} header={"Token Name"} filter={true}
                    filterMenuStyle={{width: '14rem', fontSize: "10px"}}
                    body={(params) => {
                        return RenderTokenCell(params, () => {

                        })
                        // return RenderTokenCell()
                    }}

            ></Column>

            <Column field={"costBasis"} header={"Cost Basis"} body={(params) => {
                return RenderSimpleNumberField(params.costBasis.toString())
            }}></Column>
            <Column field={"pnl"} sortable={true} header={"P&L"} body={(params) => {
                return RenderProfitLossCell(params.pnl)
            }}></Column>

            <Column field={"realizedGains"} header={"Realized Gains"} body={(params) => {
                return RenderProfitLossCell(params.realizedGains || params.pnl)
            }}></Column>
            <Column field={"unrealizedGains"} header={"Unrealized Gains / Lost"} body={(params) => {
                    return RenderProfitLossCell(params.unrealizedGains)
            }}></Column>
            <Column headerStyle={{width: '5rem', textAlign: 'center'}}
                    bodyStyle={{textAlign: 'center', overflow: 'visible'}} body={(params) => {

                let currentYear = date.getFullYear()
                let startYear = new Date(Date.UTC(currentYear, 0, 1, 0, 0, 0, 0));
                let nextYear = new Date(Date.UTC(currentYear + 1, 0, 1, 0, 0, 0, 0));
                let endYear = new Date(nextYear.getTime() - 1);


                // let yearEnd =
                return actionBodyTemplate(params, startYear.getTime() / 1000, endYear.getTime() / 1000)
            }}/>

            {/*<Column field={"costBasis"} header={"Cost Basis"} body={(params)=>{*/}
            {/*    return RenderSimpleNumberField(params.costBasis.toString())*/}
            {/*}} ></Column>*/}


        </DataTable>
    </>)
}